import { IAppConfig } from "./types";

export const appConfig: IAppConfig = {
  "main_navigation": {
    "main_items": [
      {
        "id": "navigation.main.Spielpl%C3%A4ne",
        "name": "Spielpläne",
        "title": undefined,
        "href": "/spielplansuche",
        "target": undefined,
        "action": undefined
      },
      {
        "id": "navigation.main.Ligen%20&%20Wettbewerbe",
        "name": "Ligen & Wettbewerbe",
        "title": undefined,
        "href": "/ligen",
        "target": undefined,
        "action": undefined
      },
      {
        "id": "navigation.main.Vereine",
        "name": "Vereine",
        "title": undefined,
        "href": "/vereine",
        "target": undefined,
        "action": undefined
      },
      {
        "id": "navigation.main.Verb%C3%A4nde",
        "name": "Verbände",
        "title": undefined,
        "href": "/verbaende",
        "target": undefined,
        "action": undefined
      },
      {
        "id": "navigation.main.Hallen",
        "name": "Hallen",
        "title": undefined,
        "href": "/hallen",
        "target": undefined,
        "action": undefined
      },
      {
        "id": "navigation.main.Livestreams%20&%20Videos",
        "name": "Livestreams & Videos",
        "title": undefined,
        "href": "/videocenter",
        "target": "_blank",
        "action": undefined
      },
      {
        "id": "navigation.main.News",
        "name": "News",
        "title": undefined,
        "href": "/news",
        "target": undefined,
        "action": undefined
      }
    ],
    "secondary_items": [
      {
        "id": "navigation.secondary.navigation_nationalmannschaften",
        "name": "Nationalmannschaften",
        "title": undefined,
        "items": [
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.M%C3%A4nner",
            "name": "Männer",
            "title": undefined,
            "href": "/news/tags/a-manner-deutschland",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.Frauen",
            "name": "Frauen",
            "title": undefined,
            "href": "/news/tags/a-frauen-deutschland",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.U20/21%20m%C3%A4nnlich",
            "name": "U20/21 männlich",
            "title": undefined,
            "href": "/news/tags/u20-21-mannlich",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.U19/20%20weiblich",
            "name": "U19/20 weiblich",
            "title": undefined,
            "href": "/news/tags/u19-20-weiblich",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.U18/19%20m%C3%A4nnlich",
            "name": "U18/19 männlich",
            "title": undefined,
            "href": "/news/tags/u18-19-mannlich",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.U17/18%20weiblich",
            "name": "U17/18 weiblich",
            "title": undefined,
            "href": "/news/tags/u17-18-weiblich",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.U16/17%20m%C3%A4nnlich",
            "name": "U16/17 männlich",
            "title": undefined,
            "href": "/news/tags/u16-17-mannlich",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_nationalmannschaften.U15/16%20weiblich",
            "name": "U15/16 weiblich",
            "title": undefined,
            "href": "/news/tags/u15-16-weiblich",
            "target": undefined,
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.secondary.navigation_1_bundesliga",
        "name": "1. Bundesliga",
        "title": undefined,
        "items": [
          {
            "id": "navigation.secondary.navigation_1_bundesliga.DAIKIN%20HBL",
            "name": "DAIKIN HBL",
            "title": undefined,
            "href": "/hbl",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_1_bundesliga.HBF%20-%201.%20Bundesliga",
            "name": "HBF - 1. Bundesliga",
            "title": undefined,
            "href": "/hbf",
            "target": undefined,
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.secondary.navigation_2_bundesliga",
        "name": "2. Bundesliga",
        "title": undefined,
        "items": [
          {
            "id": "navigation.secondary.navigation_2_bundesliga.HBL%20-%202.%20Bundesliga",
            "name": "HBL - 2. Bundesliga",
            "title": undefined,
            "href": "/2-hbl",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.secondary.navigation_2_bundesliga.HBF%20-%202.%20Bundesliga",
            "name": "HBF - 2. Bundesliga",
            "title": undefined,
            "href": "/2-hbf",
            "target": undefined,
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.secondary.navigation_3_liga",
        "name": "3. Liga",
        "title": undefined,
        "items": [
          {
            "id": "navigation.secondary.navigation_3_liga.first_group.",
            "name": "Männer",
            "title": undefined,
            "items": [
              {
                "id": "navigation.secondary.navigation_3_liga.%C3%9Cbersicht",
                "name": "Übersicht",
                "title": undefined,
                "href": "/3-liga-maenner",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20Nord-Ost",
                "name": "3. Liga – Staffel Nord-Ost",
                "title": undefined,
                "href": "/3-liga-maenner/staffel-nord-ost",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20Nord-West",
                "name": "3. Liga – Staffel Nord-West",
                "title": undefined,
                "href": "/3-liga-maenner/staffel-nord-west",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20S%C3%BCd",
                "name": "3. Liga – Staffel Süd",
                "title": undefined,
                "href": "/3-liga-maenner/staffel-sued",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20S%C3%BCd-West",
                "name": "3. Liga – Staffel Süd-West",
                "title": undefined,
                "href": "/3-liga-maenner/staffel-sued-west",
                "target": undefined,
                "action": undefined
              }
            ]
          },
          {
            "id": "navigation.secondary.navigation_3_liga.second_group.",
            "name": "Frauen",
            "title": undefined,
            "items": [
              {
                "id": "navigation.secondary.navigation_3_liga.%C3%9Cbersicht",
                "name": "Übersicht",
                "title": undefined,
                "href": "/3-liga-frauen",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20Nord",
                "name": "3. Liga – Staffel Nord",
                "title": undefined,
                "href": "/3-liga-frauen/staffel-nord",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20Mitte",
                "name": "3. Liga – Staffel Mitte",
                "title": undefined,
                "href": "/3-liga-frauen/staffel-mitte",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_3_liga.3.%20Liga%20%E2%80%93%20Staffel%20S%C3%BCd",
                "name": "3. Liga – Staffel Süd",
                "title": undefined,
                "href": "/3-liga-frauen/staffel-sued",
                "target": undefined,
                "action": undefined
              }
            ]
          }
        ]
      },
      {
        "id": "navigation.secondary.navigation_jugendbundesliga",
        "name": "Jugendbundesliga",
        "title": undefined,
        "items": [
          {
            "id": "navigation.secondary.navigation_jugendbundesliga.first_group.",
            "name": "Männlich",
            "title": undefined,
            "items": [
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20%E2%80%93%20M%C3%A4nnliche%20A-Jugend",
                "name": "JBLH – Männliche A-Jugend",
                "title": undefined,
                "href": "/jblh-a-jugend-maennlich",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20-%20M%C3%A4nnliche%20A-Jugend%20Qualifikation",
                "name": "JBLH - Männliche A-Jugend Qualifikation",
                "title": undefined,
                "href": "/ligen/sportradar.dhbdata.16093/spielplan",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20%E2%80%93%20M%C3%A4nnliche%20B-Jugend",
                "name": "JBLH – Männliche B-Jugend",
                "title": undefined,
                "href": "/jblh-b-jugend-maennlich",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20%E2%80%93%20M%C3%A4nnliche%20B-Jugend%20Qualifikation",
                "name": "JBLH – Männliche B-Jugend Qualifikation",
                "title": undefined,
                "href": "/ligen/sportradar.dhbdata.16984/spielplan",
                "target": undefined,
                "action": undefined
              }
            ]
          },
          {
            "id": "navigation.secondary.navigation_jugendbundesliga.second_group.",
            "name": "Weiblich",
            "title": undefined,
            "items": [
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20-%20Weibliche%20A-Jugend",
                "name": "JBLH - Weibliche A-Jugend",
                "title": undefined,
                "href": "/jblh-a-jugend-weiblich",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20-%20Weibliche%20A-Jugend%20Qualifikation",
                "name": "JBLH - Weibliche A-Jugend Qualifikation",
                "title": undefined,
                "href": "/ligen/sportradar.dhbdata.16092/spielplan",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20-%20Weibliche%20B-Jugend",
                "name": "JBLH - Weibliche B-Jugend",
                "title": undefined,
                "href": "/jblh-b-jugend-weiblich",
                "target": undefined,
                "action": undefined
              },
              {
                "id": "navigation.secondary.navigation_jugendbundesliga.JBLH%20-%20Weibliche%20B-Jugend%20Qualifikation",
                "name": "JBLH - Weibliche B-Jugend Qualifikation",
                "title": undefined,
                "href": "/ligen/sportradar.dhbdata.16985/spielplan",
                "target": undefined,
                "action": undefined
              }
            ]
          }
        ]
      },
      {
        "id": "navigation.secondary.Amateure",
        "name": "Amateure",
        "title": undefined,
        "href": "/spielplansuche",
        "target": undefined,
        "action": undefined
      },
      {
        "id": "navigation.secondary.Beachhandball",
        "name": "Beachhandball",
        "title": undefined,
        "href": "/news/tags/beachhandball",
        "target": undefined,
        "action": undefined
      }
    ],
    "info_items": [
      {
        "id": "navigation.info.navigation_beliebte_themen",
        "name": "Beliebte Themen",
        "title": undefined,
        "items": [
          {
            "id": "navigation.info.navigation_beliebte_themen.Livecenter",
            "name": "Livecenter",
            "title": undefined,
            "href": "https://live.handball.net/",
            "target": "_blank",
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.Adventskalender",
            "name": "Adventskalender",
            "title": undefined,
            "href": "/news/tags/adventskalender",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.Amateurtor%20des%20Monats",
            "name": "Amateurtor des Monats",
            "title": undefined,
            "href": "/amateurtor",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.Dreher%20um%20die%20Welt",
            "name": "Dreher um die Welt",
            "title": undefined,
            "href": "/news/tags/dreher-um-die-welt",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.Hero%20des%20Monats",
            "name": "Hero des Monats",
            "title": undefined,
            "href": "/news/tags/hero-des-monats",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.Auszeit%20%E2%80%93%20der%20Ern%C3%A4hrungsblog",
            "name": "Auszeit – der Ernährungsblog",
            "title": undefined,
            "href": "/ernaehrungs-blog",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.Gesundleben%20%E2%80%93%20der%20Blog",
            "name": "Gesundleben – der Blog",
            "title": undefined,
            "href": "/gesundheitsblog",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_beliebte_themen.DKB%20Audiodeskription",
            "name": "DKB Audiodeskription",
            "title": undefined,
            "href": "/dkb-audiodeskription",
            "target": undefined,
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.info.navigation_vereinsservice",
        "name": "Vereinsservice",
        "title": undefined,
        "items": [
          {
            "id": "navigation.info.navigation_vereinsservice.Handball.net%20f%C3%BCr%20Vereine",
            "name": "Handball.net für Vereine",
            "title": undefined,
            "href": "/widgets",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_vereinsservice.Trainercenter",
            "name": "Trainercenter",
            "title": undefined,
            "href": "https://www.dhb-trainercenter.de/",
            "target": "_blank",
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_vereinsservice.Schiedsrichterportal",
            "name": "Schiedsrichterportal",
            "title": undefined,
            "href": "https://www.dhb-schiedsrichterportal.de/",
            "target": "_blank",
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_vereinsservice.DHB%20Online%20Akademie",
            "name": "DHB Online Akademie",
            "title": undefined,
            "href": "https://www.dhb-online-akademie.de/",
            "target": "_blank",
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_vereinsservice.SpielerPlus",
            "name": "SpielerPlus",
            "title": undefined,
            "href": "https://www.spielerplus.de/",
            "target": "_blank",
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.info.navigation_fanshops",
        "name": "Fanshops",
        "title": undefined,
        "items": [
          {
            "id": "navigation.info.navigation_fanshops.DHB%20Fanshop",
            "name": "DHB Fanshop",
            "title": undefined,
            "href": "https://shop.dhb.de/",
            "target": "_blank",
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_fanshops.Bundesliga%20Fanshops",
            "name": "Bundesliga Fanshops",
            "title": undefined,
            "href": "/fanshops",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_fanshops.WePlayHandball",
            "name": "WePlayHandball",
            "title": undefined,
            "href": "https://www.weplayhandball.de/",
            "target": "_blank",
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.info.navigation_ticketshops",
        "name": "Ticketshops",
        "title": undefined,
        "items": [
          {
            "id": "navigation.info.navigation_ticketshops.DHB%20Tickets",
            "name": "DHB Tickets",
            "title": undefined,
            "href": "https://www.ticket-onlineshop.com/ols/dhb/",
            "target": "_blank",
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_ticketshops.Bundesliga%20Tickets",
            "name": "Bundesliga Tickets",
            "title": undefined,
            "href": "/ticketshops",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_ticketshops.SO-TECH%20CUP",
            "name": "SO-TECH CUP",
            "title": undefined,
            "href": "https://www.owl-arena.de/tickets-events/show/event/sotech-cup-1/",
            "target": "_blank",
            "action": undefined
          }
        ]
      },
      {
        "id": "navigation.info.navigation_uber_handball-net",
        "name": "Über handball.net",
        "title": undefined,
        "items": [
          {
            "id": "navigation.info.navigation_uber_handball-net.Was%20ist%20Handball.net?",
            "name": "Was ist Handball.net?",
            "title": undefined,
            "href": "/warum-registrieren",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_uber_handball-net.FAQ",
            "name": "FAQ",
            "title": undefined,
            "href": "/faq",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_uber_handball-net.Kontaktformular",
            "name": "Kontaktformular",
            "title": undefined,
            "href": "/kontakt",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_uber_handball-net.Newsletter-Anmeldung",
            "name": "Newsletter-Anmeldung",
            "title": undefined,
            "href": "/newsletter",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_uber_handball-net.Nutzungsbedingungen",
            "name": "Nutzungsbedingungen",
            "title": undefined,
            "href": "/nutzungsbedingungen",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_uber_handball-net.Datenschutz",
            "name": "Datenschutz",
            "title": undefined,
            "href": "https://www.dhb.de/datenschutz",
            "target": undefined,
            "action": undefined
          },
          {
            "id": "navigation.info.navigation_uber_handball-net.Impressum",
            "name": "Impressum",
            "title": undefined,
            "href": "/impressum",
            "target": undefined,
            "action": undefined
          }
        ]
      }
    ],
    "social_items": [
      {
        "id": "navigation.social.14fd004d-7513-4d5a-85fb-de55486f9ea0",
        "name": "Link",
        "title": "Instagram",
        "href": "https://www.instagram.com/handballnet",
        "target": "_blank",
        "action": undefined,
        "icon": {
          "id": "Instagram"
        }
      },
      {
        "id": "navigation.social.738b3871-01fd-4b4b-9040-74f8aa9d67cd",
        "name": "Link",
        "title": "Facebook",
        "href": "https://www.facebook.com/www.handball.net/",
        "target": "_blank",
        "action": undefined,
        "icon": {
          "id": "Facebook"
        }
      },
      {
        "id": "navigation.social.26033e83-804d-4e53-90a5-03b58512bd01",
        "name": "Link",
        "title": "TikTok",
        "href": "https://www.tiktok.com/@handball.net",
        "target": "_blank",
        "action": undefined,
        "icon": {
          "id": "TikTok"
        }
      },
      {
        "id": "navigation.social.09d48a96-2394-4510-bf01-7841780ec12f",
        "name": "Link",
        "title": "YouTube",
        "href": "https://www.youtube.com/@handballnetDE",
        "target": undefined,
        "action": undefined,
        "icon": {
          "id": "Youtube"
        }
      }
    ]
  },
  "main_shortcuts": [
    {
      "id": "main_shortcuts.Live",
      "name": "Live",
      "title": undefined,
      "href": "https://live.handball.net",
      "target": "_blank",
      "action": undefined
    },
    {
      "id": "main_shortcuts.Spielplansuche",
      "name": "Spielplansuche",
      "title": undefined,
      "href": "/spielplansuche",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.Frauen-EM",
      "name": "Frauen-EM",
      "title": undefined,
      "href": "/handball-frauen-em-2024",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.Adventskalender",
      "name": "Adventskalender",
      "title": undefined,
      "href": "/news/tags/adventskalender",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.3.%20Liga%20M%C3%A4nner",
      "name": "3. Liga Männer",
      "title": undefined,
      "href": "/3-liga-maenner",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.3.%20Liga%20Frauen",
      "name": "3. Liga Frauen",
      "title": undefined,
      "href": "/3-liga-frauen",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.JBLH%20A-m%C3%A4nnlich",
      "name": "JBLH A-männlich",
      "title": undefined,
      "href": "/jblh-a-jugend-maennlich",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.JBLH%20A-weiblich",
      "name": "JBLH A-weiblich",
      "title": undefined,
      "href": "/jblh-a-jugend-weiblich",
      "target": undefined,
      "action": undefined
    },
    {
      "id": "main_shortcuts.Amateurtor",
      "name": "Amateurtor",
      "title": undefined,
      "href": "/amateurtor",
      "target": undefined,
      "action": undefined
    }
  ],
  "mobile_tab_navigation": [
    {
      "id": "mobile_tab_navigation.Home",
      "name": "Home",
      "title": undefined,
      "href": "/",
      "target": undefined,
      "action": undefined,
      "icon": {
        "id": "Home"
      }
    },
    {
      "id": "mobile_tab_navigation.Spiele",
      "name": "Spiele",
      "title": undefined,
      "href": "/spielplansuche",
      "target": undefined,
      "action": undefined,
      "icon": {
        "id": "Goal"
      }
    },
    {
      "id": "mobile_tab_navigation.News",
      "name": "News",
      "title": undefined,
      "href": "/news",
      "target": undefined,
      "action": undefined,
      "icon": {
        "id": "List"
      }
    },
    {
      "id": "mobile_tab_navigation.Profil",
      "name": "Profil",
      "title": undefined,
      "href": undefined,
      "target": undefined,
      "action": "open-profile",
      "icon": {
        "id": "User"
      }
    },
    {
      "id": "mobile_tab_navigation.Mehr",
      "name": "Mehr",
      "title": undefined,
      "href": undefined,
      "target": undefined,
      "action": "open-menu",
      "icon": {
        "id": "MoreHorizontal"
      }
    }
  ],
  "schedule_search_shortcuts": [
    {
      "id": "topspiele-des-tages",
      "href": "#",
      "name": "Topspiele des Tages"
    },
    {
      "id": "deutschland-cup",
      "href": "#",
      "name": "Deutschland-Cup"
    }
  ]
}